.nav_bar {
    position: fixed !important;
    width: 100%;
    background: #ffffff !important;
    color: #4f747a;
    padding: 10px;
    display: flex;
    height: 66px;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.profileIconStyles {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

html[dir="rtl"] .profileIconStyles {
    margin-left: 15px;
    margin-right: 0px;
}

/* Minifying Navigation Styles */
.minifyNavigationDropdown {
    min-width: 63px !important;
    border: 1px solid rgba(105, 105, 106, 0.369) !important;
    border-radius: 3px !important;
    margin-left: -6px;
    margin-top: -35px !important;
    padding-bottom: 5px;
    height: calc(100% + 90px) !important;
}

html[dir="rtl"] .minifyNavigationDropdown {
    min-width: 63px !important;
    border: 1px solid rgba(105, 105, 106, 0.369) !important;
    border-radius: 3px !important;
    margin-right: -6px;
    margin-left: 0px;
    margin-top: -35px !important;
    padding-bottom: 5px;
    height: calc(100% + 90px) !important;
}

.minifyNavigationDropdown li {
    /* border: 1px solid rgba(105, 105, 106, 0.369) !important; */
    padding-right: 0px !important;
    padding-left: 0px !important;
    width: 63px !important;
    margin-right: 0px !important;
    border-radius: 3px !important;
    margin-bottom: 0px;
    padding-top: 5px;
}

.minifyNavigationDropdown li a:hover {
    background-color: #ffffff !important;
    transition: 0.1s linear;
    width: 59px !important;
}

.minifyNavigationDropdown li a {
    width: 63px !important;
    transition: 0.1s linear;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.minifyNavigationDropdown li a button {
    margin-left: 4px !important;
    /* margin-bottom: 1px; */
}

html[dir="rtl"] .minifyNavigationDropdown li a button {
    margin-right: 4px !important;
    margin-left: 0px;
    /* margin-bottom: 1px; */
}

.minifyNavigationDropdown li a button:hover {
    /* padding-left: 0px !important;
 padding-right: 0px !important; */
    transition: 0.1s linear;
    color: white !important;
    border: 1px solid rgb(4, 190, 10) !important;
    background-color: #4f747a !important;
}

/* Minifying Navigation Styles */

.searchBoxNavbar {
    transition: 0.1s linear;
    /* border-bottom: 0.2px solid #4f747a56; */
    border-radius: 0px;
    /* display: flex; */
    margin-left: 20px;
    margin-top: -5px;
}

html[dir="rtl"] .searchBoxNavbar {
    transition: 0.1s linear;
    /* border-bottom: 0.2px solid #4f747a56; */
    border-radius: 0px;
    /* display: flex; */
    margin-right: 20px;
    margin-left: 0px;
    margin-top: -5px;
}

.searchBoxNavbar:focus {
    /* border: 0.5px solid #aab9bb;
    box-shadow: 0 0.5px 0 0 #aab9bb; */
    /* border-bottom: 0.2px solid #4f747a56 !important; */
    box-shadow: none !important;
    border-radius: 0px !important;
}

.searchBoxNavbar:active, .searchBoxNavbar:focus {
    /* border-bottom: 0.2px solid #4f747a56; */
    border-radius: 0px !important;
}

.isSideOpen {
    width: calc(100% - 270px) !important;
    margin-left: 270px;
    /* border: 2px solid red; */
    transition: 0.1s linear;
}

html[dir="rtl"] .isSideOpen {
    width: calc(100% - 270px) !important;
    margin-right: 270px;
    margin-left: 0px;
    transition: 0.1s linear;
}

.isSideClose {
    width: 100%;
    margin-left: 0px;
    transition: 0.1s linear;
    /* border: 2px solid rgb(0, 13, 255); */
}

html[dir="rtl"] .isSideClose {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    transition: 0.1s linear;
    /* border: 2px solid rgb(0, 13, 255); */
}

.navbarHamburger {
    box-shadow: 2px 2px 2px solid rgb(0, 0, 0);
    /* border: 2px solid red; */
}

.navItems {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
}

.dropdown_nav {
    margin-left: -240px !important;
    width: 280px !important;
    z-index: 10 !important;
    border-radius: 5px !important;
    margin-top: 0px !important;
    /* border: 1px solid red !important; */
}

html[dir="rtl"] .dropdown_nav {
    margin-right: -240px !important;
    margin-left: 0px !important;
    width: 280px !important;
    z-index: 10 !important;
    border-radius: 5px !important;
    margin-top: 0px !important;
    /* border: 1px solid red !important; */
}

/* DropDown Nav Link */
.navLinkDropDown {
    border-radius: 50px;
    /* width: 30px;
    height: 30px; */
    transition: 0.3s linear;
    border: 0.3px solid transparent;
    color: #4f747a;

    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.navLinkDropDown:hover {
    /* border: 0.1px solid #4f747a; */
    /* background-color: #4f747a; */
    /* border: 0.1px solid #f7f7f7; */
    transition: 0.3s linear;
    border: 0.3px solid #4f747a;
    background: #ffffff;
    background-color: #f7f7f7;
    /* color: aliceblue; */
    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* DropDown Nav Link */

.topContainerDropDownNav {
    height: 106px;
    width: 100%;
    background-color: #4f747a;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.dropdownProfileNav {
    border-radius: 5px !important;
    width: 280px !important;
}

.dropdownProfileNav li a {
    /* border: 1px solid red; */
    height: 45px;
    padding-top: 11px;
    padding-left: 30px;
}

html[dir="rtl"] .dropdownProfileNav li a {
    /* border: 1px solid red; */
    height: 45px;
    padding-top: 11px;
    /* padding-right: 30px !important; */
    padding-left: 30px !important;
}

.dropdownProfileNav li a:hover {
    /* border: 1px solid red; */
    background-color: #f8f9fa;
    color: #4f747a;
    height: 45px;
    padding-top: 11px;
    padding-left: 30px;
}

html[dir="rtl"] .dropdownProfileNav li a:hover {
    /* border: 1px solid red; */
    background-color: #f8f9fa;
    color: #4f747a;
    height: 45px;
    padding-top: 11px;
    /* padding-right: 30px !important; */
    padding-left: 30px !important;
}

/* @@@@@@@@@@@@@@@@@@@@ Profile Inside Container @@@@@@@@@@@@@@@@@@@@@ */
.insideContainerProfile {
    /* border: 1px solid red; */
    display: flex;
    padding-top: 30px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.imageProfile {
    border-radius: 50%;
    /* margin-left: 30px; */
}

.rightInsideProfile {
    margin-left: 10px;
}

html[dir="rtl"] .rightInsideProfile {
    margin-right: 10px;
    margin-left: 0px;
}

.rightInsideProfile h3 {
    margin-top: 3px;
    font-size: 20px;
    color: white;
}

.rightInsideProfile p {
    color: white;
    margin-top: -10px;
    font-size: 15px;
}

/* @@@@@@@@@@@@@@@@@@@@ Profile Inside Container @@@@@@@@@@@@@@@@@@@@@ */

/* {{{{{{{{{{{{{{{{{{{{{{{{{{{{ Notification DropDown Container }}}}}}}}}}}}}}}}}}}}}}}}}}}} */
.dropdownNotificationNav {
    /* border: 2px solid red !important; */
    width: 350px !important;
    margin-left: -280px !important;
}

html[dir="rtl"] .dropdownNotificationNav {
    /* border: 2px solid red !important; */
    width: 350px !important;
    margin-right: -280px !important;
    margin-left: 0px !important;
}

.topContainerNotificationNav {
    /* border: 1px solid red !important; */
    height: 85px;
    width: 100%;
    background-color: #4f747a;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: white;
}

.topContainerNotificationNav h3 {
    text-align: center;
    width: 100%;
    padding-top: 18px;
    font-size: 20px;
    color: white;
    /* border: 1px solid red !important; */
}

.topContainerNotificationNav p {
    text-align: center;
    width: 100%;
    /* border: 1px solid red !important; */

    color: white;
    margin-top: -8px;
    font-size: 15px;
}

/* ((((((((((((((((((((((( Tabs Part )))))))))))))))))))))))) */

.TabsContainerNotificationNav {
    border-bottom: 0.3px solid #4f747a49;
    transition: 0.1s linear;
    display: flex;
    height: 50px;
}

.TabsContainerNotificationNav h3 {
    /* border-bottom: 1px solid red; */
    font-size: 15px;
    width: 33.3%;
    text-align: center;
    height: 50px;
    padding-top: 17px;
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.1s linear;
}

.activeDropDownTabStyle {
    border-bottom: 1px solid #6bb6b5;
    transition: 0.1s linear;
    letter-spacing: 3px !important;
    font-weight: bold !important;
    color: #4f747a;
}

/* ((((((((((((((((((((((( Tabs Part )))))))))))))))))))))))) */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& Message Container &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */
.messageList {
    /* border: 1px solid red; */
    overflow: auto;
    height: 420px;
}

.individualMessageList {
    width: 100%;
    display: flex;
    height: 100px;
    border-bottom: 0.1px solid rgba(0, 0, 0, 0.212) !important;
    /* border: 2px solid blue; */
}

html[dir="rtl"] .individualMessageList {
    flex-direction: row-reverse;
}

.individualMessageList:hover {
    background-color: rgba(128, 128, 128, 0.125);
    cursor: pointer;
}

.leftSideML {
    width: 30%;
    height: 100%;
    /* border: 1px solid rgb(64, 255, 0); */
    text-align: center;
    padding-top: 18px;
}

/* ************* ************* Right Side ML ************* ************* */
.rightSideML {
    width: 70%;
    height: 100%;
    /* border: 1px solid rgb(0, 81, 255); */
}

.nameML {
    font-size: 16px;
    /* border: 1px solid red; */
    margin-top: 19px;
}

html[dir="rtl"] .nameML {
    /* border: 1px solid red; */
    padding-left: 5%;
}

.messageML {
    line-height: 15px;
    font-size: 12px !important;
    height: 14px;
    margin-top: -3px !important;
    display: -webkit-box !important;
    max-width: 220px !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    /* border: 1px solid rgb(0, 42, 255); */
}

.timeML {
    margin-top: -13px;
    font-size: 12px !important;
    color: grey;
}

html[dir="rtl"] .timeML {
    padding-left: 5%;
}

.eventContainer h5 {
    margin-top: 20px;
}

/* ************* ************* Right Side ML ************* ************* */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& Message Container &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */

/* %%%%%%%%%%%%%%%%%%%%%%% Notifcations container %%%%%%%%%%%%%%%%%%%% */
.notificationsContainer {
    height: 420px;
    overflow: auto;
}

.individualNotificationList {
    width: 100%;
    height: 100px;
    padding-left: 5%;
    border-bottom: 0.1px solid rgba(0, 0, 0, 0.212) !important;
    /* border: 2px solid blue; */
}

html[dir="rtl"] .individualNotificationList {
    padding-right: 5%;
    padding-left: initial !important;
}

.individualNotificationList:hover {
    background-color: rgba(128, 128, 128, 0.125);
    cursor: pointer;
}

.nlTitle {
    font-size: 16px;

    padding-top: 12px;
}

html[dir="rtl"] .nlTitle {
    /* border: 1px solid red; */
    padding-left: 5% !important;
}

.nlMessage {
    /* border: 1px solid red; */
    line-height: 15px;
    font-size: 12px !important;
    height: fit-content;
    margin-top: -3px !important;
    display: -webkit-box !important;
    /* max-width: 90% !important; */
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}

html[dir="rtl"] .nlMessage {
    /* border: 1px solid red; */
    padding-left: 5% !important;
    padding-right: initial !important;
}

.nlTime {
    margin-top: -13px;
    font-size: 12px !important;
    color: grey;
}

html[dir="rtl"] .nlTime {
    /* border: 1px solid red; */
    padding-left: 5% !important;
    padding-right: initial !important;
}

/* %%%%%%%%%%%%%%%%%%%%%%% Notifcations container %%%%%%%%%%%%%%%%%%%% */

/* {{{{{{{{{{{{{{{{{{{{{{{{{{{{ Notification DropDown Container }}}}}}}}}}}}}}}}}}}}}}}}}}}} */

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

/* {{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{ App DropDown Container }}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}} */
.dropdownAppNav {
    /*
    height: 85px !important; */
    width: 350px !important;
    margin-left: -220px !important;
}

html[dir="rtl"] .dropdownAppNav {
    margin-right: -220px !important;
    margin-left: 0px !important;
}

.topContainerAppNav {
    /* border: 1px solid red !important; */
    height: 85px;
    width: 100%;
    background-color: #4f747a;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: white;
}

.topContainerAppNav h3 {
    text-align: center;
    width: 100%;
    padding-top: 18px;
    font-size: 20px;
    color: white;
    font-weight: 400;
    /* border: 1px solid red !important; */
}

.topContainerAppNav p {
    text-align: center;
    width: 100%;
    /* border: 1px solid red !important; */
    font-weight: lighter;
    color: white;
    margin-top: -8px;
    font-size: 15px;
}

/* Inside Apps List Container DropDown */
.AppsContainerDropDown {
    /* border: 1px solid red; */
    width: 100%;
    height: 370px;
    overflow: auto;
    /* height: 20px; */
}

.insideContainerAC {
    /* border: 1px solid red; */
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    transition: 0.3s ease-in-out;
    /* Display such that I can display all the items in a grid each row containing three items and proper space so that they are properly aligned */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    /* border: 1px solid red; */
}

.insideContainerAC li {
    width: 100px;
    height: 95px;
    border-radius: 5px;
    /* border: 1px solid rgb(100, 100, 100); */
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-align: center;
    padding-top: 10px;
}

html[dir="rtl"] .insideContainerAC li {
    width: auto;
    height: 95px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    text-align: center;
    padding-top: 10px;
}

.insideContainerAC li:hover {
    background-color: #f7f7f7;
    transition: 0.2s linear;
    color: #4f747a;
    transform: scale(1.1);
}

.selectedAppStyle {
    background-color: #f7f7f7;
    transition: 0.2s linear;
    color: #4f747a;
    /* transform: scale(1.1); */
}

/* Inside Apps List Container DropDown */

/* .isSideOpen {
    width: calc(100% - 270px) !important;
    margin-left: 270px;
    transition: 0.1s linear;
}

.isSideClose {
    width: 100%;
    margin-left: 0px;
    transition: 0.1s linear;
} */

/* Minified Sidebar CSS */
.isSideOpenMinified {
    width: 95.55% !important;
    margin-left: 80px !important;
    transition: 0.1s linear;
    z-index: 1;
    /* border: 1px solid blue !important; */
}

html[dir="rtl"] .isSideOpenMinified {
    margin-right: 80px !important;
    margin-left: 0px !important;
}

/* Minified Sidebar CSS */

/* {{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{ App DropDown Container }}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}} */

@media all and (max-width:990px) {
    .isSideOpen {
        width: 100% !important;
        margin-left: 0px;
        /* border: 2px solid rgb(77, 255, 0); */
        transition: 0.1s linear;
        z-index: 1;
    }

    html[dir="rtl"] .isSideOpen {
        width: 100% !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
        /* border: 2px solid rgb(77, 255, 0); */
    }

    .dropdownProfileNav .isSideClose {
        width: 100%;
        margin-left: 0px;
        transition: 0.1s linear;
        /* border: 2px solid rgb(0, 13, 255); */
    }

    html[dir="rtl"] .isSideClose {
        margin-right: 0px !important;
        margin-left: 0px !important;
        /* border: 2px solid rgb(0, 13, 255); */
    }
}

@media all and (max-width:770px) {
    .searchBoxNavbar {
        /* border: 1px solid rgb(0, 115, 255); */
        display: none;
    }
}


@media print {
    .style_isSideOpen__ZgcY {
      display: none; /* Hide navbar when printing */
    }
  }