.container {
    border:0.5px solid rgb(219, 219, 219);
    /* border: 0.5px solid red; */
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 100% !important;
    height: 100%;
}

/* Header Container Starts here */
.headerContainer{
    border-bottom: 0.5px solid rgb(219, 219, 219);
    width: 100%;
    height: 48px;
    padding-left: 12px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

html[dir="rtl"] .headerContainer{
    padding-right: 12px;
    padding-left: 0px;
}

.headingTopLeft{
    font-size: 18px;
    font-weight: lighter;
    padding-top: 13px;
}

.tableSubTitleTopLeft{
    font-size: 18px;
    font-weight: lighter;
    color: black;
    padding-left: 20px;
    padding-top: 2.5px;
}
html[dir="rtl"] .tableSubTitleTopLeft{
    padding-left: 0px;
    padding-right: 20px;
}

.headerRight{
    display: flex;
}

.headerButtonContainer{
    display: flex;
    padding-top: 15px;
    margin-right: 15px;
}

.btnControls{
    width: 16px !important;
    height: 16px !important;
    border-radius: 50px !important;
    /* background-color: black; */
    margin-left: 7px !important;
    cursor: pointer !important;
}
html[dir="rtl"] .btnControls{
    margin-left: 0px !important;
    margin-right: 7px !important;
}

.btnControls:hover{
    background-color: grey;
}
.btnDropDownTableBtn{
    cursor: pointer;
    width: 30px;
    border-radius: 0.3px solid black;
    /* border: 1px solid black; */
    /* border-left: 1px solid white; */
}
.btnDropDownTableBtn:hover,.btnDropDownTableBtn:active{
    background-color: transparent !important;
    transition:0.3s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
    /* border: 0px solid #f4f4f4 !important; */
}
/* Header Ends Here */

/* Body Starts Here */
.bodyContainer{
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
}

/* Body Header Starts Here */
.containerbodyHeader{
    margin-top: 20px;
    /* border: 1px solid red; */
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
html[dir="rtl"] .containerbodyHeader{
    padding-right: 12px;
    padding-left: 12px;
}

.btnControl{
    /* border: 0.5px solid rgb(219, 219, 219); */
    align-items: center;
    text-align: center;
    border-radius: 3px;
    margin-left: 5px;
}
html[dir="rtl"] .btnControl{
    margin-left: 0px;
    margin-right: 5px;
}

.insideBtnControl{
    text-transform: none !important;
    color: #4a7174 !important;
    width: 100% !important;
    height: 45px !important;
    /* border:1px solid red; */
}
/* Body Header Starts Here */
.bodyOfBodyContainer{
   /* width: 98%;
   margin-left: 1%;
   margin-right: 1%; */
   /* border: 1px solid red; */
   min-height: 100px !important;
}
/* Body Ends Here */

/* Footer Starts here */
/* .footerContainer{
    height: 40px;
    width: 100%;
    border: 1px solid green;
} */

.btnContainerTable{
    display: flex;
}

@media all and (max-width:1400px){
    .container {
        /* border:0.5px solid rgb(219, 219, 219); */
        /* border: 0.5px solid red; */
        width: 100%;
        margin-left:auto;
        margin-right:auto;
    }
}

@media all and (max-width:600px){
    .containerbodyHeader{
        flex-direction: column;
        justify-content: start;
        align-items: left;
    }
    .btnContainerTable{
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 15px;
        margin-left: 0px !important;
        padding-left: 0px !important;

    }
    .btnControl{
        width: 30% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0px !important;
        margin-left: 0px !important;
    }
    .bodyOfBodyContainer{
        width: 97%;
        margin-left: 1.5%;
        min-height: 400px !important;
        /* border:2px solid red !important; */
    }
    html[dir="rtl"] .bodyOfBodyContainer{
        width: 97%;
        margin-right: 1.5%;
        margin-left: 0px;
    }
}