.container {
    /* border: 1px solid red; */
    width: 100% !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

/* Top Container Starts */
.topContainer {
    /* border: 1px solid rgb(12, 47, 246); */
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

/* Left Top Container */
.leftTopContainer {
    display: flex;
    padding-top: 16px;
}

/* Right Top Container */
.rightTopContainer {
    display: flex;
}

.topContainerLeftText {
    font-size: 22px;
    margin-left: 10px;
    font-weight: 300;
    color: #767676;
}

html[dir="rtl"] .topContainerLeftText {
    margin-left: 0px;
    margin-right: 10px;
}

/* Left Top Container */

.progressBarTopContainer {
    display: flex;
    /* border: 1px solid red; */
    padding-top: 2px;
    margin-right: 20px;
}

html[dir="rtl"] .progressBarTopContainer {
    margin-left: 20px;
    margin-right: 0px;
}

.containerRightProgress {
    /* border: 1px solid black; */
    height: 60px;
    padding-left: 10px;
}

html[dir="rtl"] .containerRightProgress {
    padding-left: 0px;
    padding-right: 10px;
}


.boxLabel {
    padding-left: 10px;
    position: relative;
    /* font-weight:800 ; */
    /* padding-top: 5px; */
    top: -16px;
    left: 55px;
    background-color: #3c6766;
    padding: 0 8px;
    color: #fff;
    width: 150px;
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
}

/* Top Container Over */

/* Box Container Starts */
.containerBoxes {
    margin-top: 30px;
    padding-left: 40px;
    /* padding-right: 0px; */
    width: 96.7% !important;
    background-color: red;

}

/* Inside Container Box Styles */
.insideContainerBox {
    /* border: 1px solid black; */
    border-radius: 20px;
    height: 120px;
    /* padding-left: 20px; */
    padding-top: 5px;
    text-align: center;
    /* width: 250px; */
}

html[dir="rtl"] .insideContainerBox {
    padding-left: 0px;
    padding-right: 20px;
}

.insideContainerBox:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
}

.reportName {
    font-size: 30px;
    font-weight: 500;
    color: white;
    user-select: none;
    align-items: center;
}

.description {
    font-size: 15px;
    color: white;
    font-weight: normal;
    margin-top: -10px;
}

.status {
    padding-right: 15px;
}

.tableRow {
    border-top: 10px solid rgba(211, 211, 211);
}

.formHeading {
    padding-top: 6px;
    font-size: 20px;
    font-family: auto;
    padding-bottom: 60px;
    /* padding-left: 30px; */
    /* justify-content: flex-start; */
}

.universityLogo {
    padding-top: 0px;
    padding-bottom: 2px;
}

html[dir="rtl"] .universityLogo {
    padding-top: -4px;
    padding-bottom: 5px;
    padding-left: 20px;
    height: 130px;
}

.collegeNameHeading {
    padding-left: 40px;
    padding-top: 10px;
}

.tablecontainer {
    border: 5px solid #3c6766;
    margin-top: 20px;
}

.label {
    width: 180px;
}

html[dir="rtl"] .label {
    width: 180px;
    text-align: right;
}

html[dir="rtl"] .thirdColumn {
    width: 200px;
    text-align: right;
}

html[dir="rtl"] .firstColumn {
    /* margin-left: 0px; */
    /* margin-right: 10px; */
    /* background-color: red; */
    text-align: right;
}

html[dir="rtl"] .tableCellValue {
    text-align: right;
    font-size: 15px;
}

.tableCellValue {
    font-size: 15px;
}


.firstColumn {
    width: 120px;
}

.thirdColumn {
    width: 400px;
}
.thirdColumnCloTable{
    width: 60px;
}

.labelFont {
    font-weight: bold;
    font-size: 15px;
}

.customtable {
    /* border-collapse: collapse; */
    width: 100%;
}

.customtable th,
.customtable td {
    border: 1px solid grey;
    /* Add borders to table cells */
    padding: 8px;
    /* Adjust padding as needed */
    text-align: left;
}

/* Inside Container Box Styles */

/* Box Container Ends */

/* For Responsivenesss */

@media all and (max-width:1400px) {
    .containerBoxes {
        /* border: 1px solid red; */
        width: 96% !important;
    }
}

@media all and (max-width:1100px) {
    .containerBoxes {
        /* border: 1px solid rgb(17, 0, 255); */
        width: 95% !important;
    }
}

@media all and (max-width:900px) {
    .containerBoxes {
        /* border: 1px solid rgb(38, 255, 0); */
        width: 94% !important;
    }
}

@media all and (max-width:870px) {
    .containerBoxes {
        /* border: 1px solid rgb(38, 255, 0); */
        width: 93.5% !important;
    }

    .insideContainerBox {
        /* border: 1px solid black; */
        height: 220px;
    }
}

@media all and (max-width:767px) {
    .containerBoxes {
        /* border: 1px solid rgb(38, 255, 0); */
        width: 93% !important;
    }

    .insideContainerBox {
        /* border: 1px solid rgb(255, 0, 0); */
        height: 110px;
        padding-left: initial;
        text-align: center;
        margin-bottom: 20px;
    }

    .topContainer {
        /* border: 1px solid rgb(12, 47, 246); */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }
}

@media all and (max-width:600px) {
    .containerBoxes {
        /* border: 1px solid rgb(255, 0, 0); */
        width: 92% !important;
    }

    .insideContainerBox {
        border-radius: 0px;
        height: 80px;
        padding-top: 4px;
    }

    .reportName {
        font-size: 20px;
        font-weight: 500;
        color: white;
        user-select: none;
    }

    .description {
        font-size: 8px;
        color: white;
        font-weight: normal;
        margin-top: -10px;
    }

    .status {
        padding-right: 10px;
    }
}

@media all and (max-width:500px) {
    .containerBoxes {
        /* border: 1px solid rgb(0, 136, 255); */
        width: 89% !important;
    }
}

@media all and (max-width:479px) {
    .containerBoxes {
        margin-top: 20px;
    }

    /* Right Top Container */
    .rightTopContainer {
        flex-direction: column;
    }

    .progressBarTopContainer {
        margin-bottom: 20px;
    }
}


@media all and (max-width:450px) {
    .containerBoxes {
        /* border: 1px solid rgb(255, 0, 0); */
        width: 88% !important;
    }
}

@media all and (max-width:400px) {
    .containerBoxes {
        /* border: 1px solid rgb(0, 30, 255); */
        width: 86% !important;
    }
}

@media all and (max-width:360px) {
    .containerBoxes {
        /* border: 1px solid rgb(255, 0, 0); */
        width: 84% !important;
    }
}

@media all and (max-width:330px) {
    .topContainerLeftText {
        font-size: 21px;
        text-align: center;
        display: flex;
        justify-content: space-between;
    }

    .topContainerLeftText b {
        margin-right: 10px;
    }

    html[dir="rtl"] .topContainerLeftText b {
        margin-left: 10px;
        margin-right: 0px;
    }
}


.instructions {
    display: inline-block;
    width: 50px;
    height: 50px;
    /* margin-top: 12px; */
    border-radius: 50%;
    text-align: center;
    padding-top: 5px;
    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.instructions:hover {
    transition: 0.3s linear;
    background: #ffffff;
    background-color: #f7f7f7;
    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.tableStyle {
    text-align: center;
}


  