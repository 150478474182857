body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: scroll !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Sakkal Majalla';
  src: url('./assets/Fonts/SakkalMajjal/Sakkal\ Majalla\ Regular.ttf') format('truetype');
}

html[dir="rtl"] body,
html[dir="rtl"] h1,
html[dir="rtl"] h2,
html[dir="rtl"] h3,
html[dir="rtl"] h4,
html[dir="rtl"] h5,
html[dir="rtl"] h6,
html[dir="rtl"] p,
html[dir="rtl"] span,
html[dir="rtl"] a,
html[dir="rtl"] label,
html[dir="rtl"] input,
html[dir="rtl"] textarea,
html[dir="rtl"] select,
html[dir="rtl"] button,
html[dir="rtl"] .form-control,
html[dir="rtl"] .form-control:focus,
html[dir="rtl"] .form-control:focus~.form-notch .form-notch-middle,
html[dir="rtl"] .form-control:focus:not([readonly])~.form-notch .form-notch-trailing,
html[dir="rtl"] .form-control~.form-notch .form-notch-leading,
html[dir="rtl"] .form-control~.form-notch .form-notch-trailing,
html[dir="rtl"] .form-label,
html[dir="rtl"] .css-pdct74-MuiTablePagination-selectLabel,
html[dir="rtl"] .MuiTablePagination-selectLabel,
html[dir="rtl"] .css-levciy-MuiTablePagination-displayedRows,
html[dir="rtl"] input[type=checkbox],
html[dir="rtl"] .dropdown-menu li,
html[dir="rtl"] .dropdown-menu .dropdown-submenu,
html[dir="rtl"] .dropdown-menu .dropdown-submenu-left,
html[dir="rtl"] .dropdown-menu>li:hover>.dropdown-submenu {
  font-family: 'Sakkal Majalla' !important;
}

/* input[type=text]:focus:not([readonly])+label,.form-control input[type=password]:focus:not([readonly])+label {
  color: #aab9bb;
}

input[type=text]:focus:not([readonly]),input[type=password]:focus:not([readonly]) {
  border: 0.5px solid #4f747a;
  box-shadow: 0 0.5px 0 0 #aab9bb;
} */

/* .form-control:focus:not([readonly]) {
  border: .15rem solid #4f747a !important;
}

.form-control {
  border: inherit !important;
}

.form-control:focus:not([readonly])~.form-notch .form-notch-trailing {
  border: .125rem solid #4f747a !important;
} */

/* .form-control:focus~.form-notch .form-notch-middle {
  border: 0 !important;
  border-bottom: .125rem solid #4f747a !important;
} */

.form-control~.form-notch .form-notch-leading {
  border-radius: 10px 0 0 10px !important;
}

.form-control~.form-notch .form-notch-trailing {
  border-radius: 0 10px 10px 0 !important;
}

.form-label {
  color: #4f747a !important;
}

.css-pdct74-MuiTablePagination-selectLabel, .MuiTablePagination-selectLabel, .css-levciy-MuiTablePagination-displayedRows {
  /* border: 1px solid rgb(0, 0, 0) !important; */
  margin-top: 16px !important;
}

input[type=checkbox] {
  accent-color: #6bb6b5;
  color: white !important;
  font-size: 30px !important;
  background-color: white !important;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  top: 0px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Calender CSS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
.responsive-calendar {
  /* by setting font-size, all the elements will correspond */
  font-size: 9px !important;
  /* default to 10px */
}

@media (max-width: 1500px) {
  .responsive-calendar {
    font-size: 8px !important;
  }
}

@media (max-width: 1200px) {
  .responsive-calendar {
    font-size: 7px !important;
  }
}

@media (max-width: 768px) {
  .responsive-calendar {
    font-size: 6px !important;
  }
}

/* Large screens */
@media (min-width: 2500px) {
  .responsive-calendar {
    font-size: 12px !important;
  }
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Calender CSS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

/* @@@@@@@@@@@@@@@@@@@@@@@@ EVENT CALENDER CSS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
:root {
  --fc-small-font-size: .85em !important;
  --fc-page-bg-color: #fff !important;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3) !important;
  --fc-neutral-text-color: #808080 !important;
  --fc-border-color: #ddd !important;

  --fc-button-text-color: #fff !important;
  --fc-button-bg-color: #4f747a !important;
  --fc-button-border-color: #4f747a !important;
  --fc-button-hover-bg-color: #4f747ac3 !important;
  --fc-button-hover-border-color: #4f747ac3 !important;
  --fc-button-active-bg-color: #4f747ac3 !important;
  --fc-button-active-border-color: #4f747ac3 !important;

  --fc-event-bg-color: #7ee90c !important;
  --fc-event-border-color: #4df610 !important;
  --fc-event-text-color: #fff !important;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25) !important;

  --fc-more-link-bg-color: #d0d0d0 !important;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3) !important;
  --fc-bg-event-color: rgb(143, 223, 130) !important;
  --fc-bg-event-opacity: 0.3 !important;
  --fc-highlight-color: rgba(188, 232, 241, 0.3) !important;
  --fc-today-bg-color: rgba(255, 220, 40, 0.15) !important;
  --fc-now-indicator-color: red !important;
}

.fc-col-header-cell-cushion {
  color: #4f747a !important;
}

.fc-daygrid-day-number {
  text-align: center !important;
  color: #4f747a !important;
  padding-right: 15px !important;
  padding-top: 20px !important;
}

.fc-toolbar-title {
  font-size: 20px !important;
  padding-right: 12px !important;
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EVENT CALENDER CSS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */


/* ENABLE BOOTSTRAP DROPDOWN ON HOVER */
.NavigationDropDown:hover .dropdown-menu {
  display: block !important;
  margin-top: 0;
}

/* ENABLE BOOTSTRAP DROPDOWN ON HOVER */


/* For Ripple Effect */

/* For Ripple Effect */


/* For Table Pagination */
.MuiBox-root {
  direction: ltr;
}




.jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right {
  margin: 0 0 0 8px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  display: none !important;
}

.jodit-container{
  
}


/* Overriding Base Styles */

/* .dropdown-menu,  */
/* .dropdown-menu-button,  */
/* .help-icon,
.sidebar-trigger {
  display: none !important;
} */


.dropdown-menu-button, 
	.help-icon,
	.sidebar-trigger {
	  display: none !important;
	}