/* User Info Modal Style */
.modalStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 700px;
    background-color: #ffffff;
    box-shadow: 24;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow: auto;
}

.loader {
    height: 80vh;
}

/* Title */
.transition_modal_title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: #54787e;
    margin-bottom: 0.5rem;
    margin-top: 0;
    text-align: center;
}

/* Header Info */
.modalHeader {
    /* border: 1px solid red !important; */
    display: flex !important;
    justify-content: center !important;
    margin-top: 30px !important;
    padding-top: 25px;
}

html[dir="rtl"] .modalHeader {
    flex-direction: row-reverse !important;
}

/* Left Header */
.modalHeaderLeft {
    /* border: 1px solid green !important; */
    text-align: center !important;
}

.modalHeaderLeft img {
    width: 130px !important;
    height: 130px !important;
    border-radius: 50% !important;
    border: 1px solid #b7cfd3 !important;
    /* transition: 2s linear; */
    /* animation: flip-with-scale 0.5s linear;
    animation-iteration-count: infinite; */
}

.modalHeaderLeft img:hover {
    border: 1px solid #b7cfd3 !important;
    /* transform: rotate(90deg) !important; */
    cursor: pointer !important;
    animation: flip 2s linear infinite !important;

}

.editIcon {
    background-color: #ffffff71;
    width: 35px !important;
    height: 35px !important;
    border-radius: 50% !important;
    border: 1px solid #b7cfd3 !important;
    text-align: center !important;
    position: relative;
    right: 35px;
}

.editIcon .editIconColor:hover {
    color: #4F747A;
    cursor: pointer;
}

.loadingIcon {
    top: 204px;
    /* background-color: red; */
    height: 30px;
    position: absolute;
    left: 180px;

}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }

    25% {
        transform: rotateY(90deg);
    }

    50% {
        transform: rotateY(180deg);
    }

    75% {
        transform: rotateY(270deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

/* Left Header */
/* Right Header */
.modalHeaderRight {
    /* border: 1px solid blue; */
    margin-left: 30px !important;
    padding-top: 10px !important;
    /* border-left: 1px solid #e0e0e0 !important; */

}

html[dir="rtl"] .modalHeaderRight {
    margin-left: 0px !important;
    margin-right: 30px !important;
    /* border-left: 0px !important; */
    /* border-right: 1px solid #e0e0e0 !important; */
    text-align: right !important;
}

/* Name */
.headerName {
    font-size: 1.8rem !important;
}

/* Email */
.headerEmail {
    font-size: 1.2rem !important;
    color: #2361d6 !important;
    cursor: pointer !important;
}

.headerEmail:hover {
    color: #2361d6 !important;
    text-decoration: underline !important;
}

.headerSubInfo {
    font-size: 1.2rem !important;
    color: #000000 !important;
    margin-top: 5px !important;
}

/* Right Header */
/* Header Info */

/* Modal Body Styles */
.modalBody {
    /* border: 1px solid red !important; */
    margin-top: 20px !important;
    /* padding-top: 20px !important; */
    /* padding-bottom: 20px !important; */
    padding-left: 5px !important;
    padding-right: 5px !important;
    border-top: 1px solid lightgray !important;
}

.modalBodyTitle {
    margin-top: 10px !important;
    /* background-color: #4f747a !important; */
    padding: 5px;
    color: #000000 !important;
    border-bottom: 0.2px solid lightgray;
    border-width: 0.2px !important;
    /* border-radius: 5px; */

    display: flex;
}
.modalBodyTitle1 {
    margin-top: 10px !important;
    /* background-color: #4f747a !important; */
    padding: 5px;
    color: #000000 !important;
    /* border-bottom: 0.2px solid lightgray; */
    border-width: 0.2px !important;
    /* border-radius: 5px; */

    display: flex;
}

html[dir="rtl"] .modalBodyTitle {
    /* justify-content: flex-end !important; */
}

.modalBodyTitleIcon {
    display: flex;
}

html[dir="rtl"] .modalBodyTitleIcon {
    flex-direction: row-reverse !important;
    /* justify-content: flex-end !important; */
    /* border: 1px solid red !important; */
}

.modalBodySocial {
    margin-top: 15px !important;
    cursor: pointer;
    display: flex;
}

html[dir="rtl"] .modalBodySocial {
    flex-direction: row-reverse !important;
}

/* Modal Body Styles */

/* Modal Footer */
.modalFooter {
    /* border: 1px solid red !important; */
    margin-top: 20px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    display: flex !important;
    justify-content: center !important;
    /* border-top: 1px solid lightgray !important; */
}

.modalFooterButton {
    font-size: 1rem !important;
    /* width: 200px !important; */
}

/* Modal Footer */

@media all and (max-width: 900px) {
    .modalStyle {
        width: 90%;
        /* height: 90vh; */
        overflow-y: auto;
        /* top: 0;
        left: 0;
        transform: none;
        border-radius: 0; */
        margin-top: 0vh;
        margin-bottom: 10px;
    }
}

@media all and (max-width: 730px) {
    .modalStyle {
        height: 90vh;
        overflow-y: auto;
    }
}

@media all and (max-width:500px) {

    /* Header Info */
    .modalHeader {
        /* border: 1px solid red !important; */
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        margin-top: 30px !important;
        padding-top: 25px;

    }

    /* Left Header */
    .modalHeaderLeft {
        /* border: 1px solid green !important; */
        text-align: center !important;

    }

    .modalHeaderLeft img {
        width: 130px !important;
        height: 130px !important;
        border-radius: 50% !important;
        border: 1px solid #b7cfd3 !important;
        /* transition: 2s linear; */
        /* animation: flip-with-scale 0.5s linear;
    animation-iteration-count: infinite; */
    }

    .modalHeaderLeft img:hover {
        border: 1px solid #b7cfd3 !important;
        /* transform: rotate(90deg) !important; */
        cursor: pointer !important;
        animation: flip 2s linear infinite !important;

    }

    /* Left Header */
    /* Right Header */
    .modalHeaderRight {
        /* border: 1px solid blue; */
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    /* Name */
    .headerName {
        font-size: 1.8rem !important;
    }

    /* Email */
    .headerEmail {
        font-size: 1.2rem !important;
        color: #2361d6 !important;
        cursor: pointer !important;
    }

    .headerEmail:hover {
        color: #2361d6 !important;
        text-decoration: underline !important;
    }

    .headerSubInfo {
        font-size: 1.2rem !important;
        color: #000000 !important;
        margin-top: 5px !important;
    }
}


.muiltr-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-top: 16px !important;
}