.headerContainer {
    display: flex;
    justify-content: space-between;
    height: 100px;
    background-color: transparent;
    width: 100%;
    padding-top: 2%;
    /* padding-left: 12%;
    padding-right: 12%; */
    position: fixed;
    /* margin-left: -0.5%; */
    /* border: 1px solid red; */
    z-index: 2;
}

.dropDownContainer {
    direction: ltr;
    display: flex;
    height: 25px;
    /* border: 1px solid red; */
}

.liElementDropDown {
    border: 0.1px solid transparent !important;
}

.liElementDropDown:hover {
    background-color: #49748200 !important;
    border: 0.1px solid rgba(255, 255, 255, 0.179) !important;
    transition: 0.4s linear;
}

.dropDownContainer p {
    padding-left: 20px;
    padding-top: 2px;
    color: white;
}

.dropDownContainer p:hover {
    /* color: #395a60; */
    transition: 0.4s linear;
}

/* html:not([data-scroll='0'])  {
    transition: 1s linear;
    background-color: red;
} */

html:not([data-scroll="0"]) .headerContainer {
    position: fixed;
    top: 0;
    width: 100%;
    /* background-color: #fff; */
    opacity: 1;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    transition: 0.4s linear;
    background-color: #395a60;
}

/* Inside Left & Right Containers */
.leftContainer {
    /* border: 1px solid red; */
    display: block;
    margin-left: 13.2%;
}

.rightContainer {
    /* border: 1px solid red; */
    margin-right: 13.2%;
    display: block;
}

html[dir=rtl] .leftContainer {
    margin-left: 0px;
    margin-right: 13.2%;
}

html[dir=rtl] .rightContainer {
    margin-right: 0px;
    margin-left: 13.2%;
}

/* Inside Left & Right Containers */

.logo {
    display: block;
    width: 190px;
    height: 60px;
    user-select: none;
    /* border: 1px solid rgb(255, 0, 0); */
}

.langDropDown {
    cursor: grab;
    color: white;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    height: 45px;
    padding-left: 10px;
    padding-right: 20px;
    border-radius: 5px;
    user-select: none;
    margin-right: 3px !important;
    /* border: 1px solid red; */
    width: 70px;
}

[dir=rtl] .langDropDown {
    /* padding-right: 10px;
    padding-left: 20px; */
    margin-right: 0px !important;
    margin-left: 13px !important;
}

.langDropDown:active, .langDropDown:hover {
    background-color: #497482;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 1s linear;
}

.langDropDown img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.langDropDown p {
    margin-left: 5px;
    /* font-size: 18px; */
}

.menuDropDown {
    display: none;
}

.dropDownLang {
    background-color: #395a6000 !important;
    border: 0.1px solid rgba(255, 255, 255, 0.179) !important;
}

.dropDownLang:hover {
    background-color: #395a6000 !important;
    color: #395a60 !important;
}

@media all and (max-width:1450px){
    .headerContainer {
        padding-top: 2.5%;
    }
    
}

@media all and (max-width:1150px) {
    .headerContainer {
        padding-top: 3%;
    }
    .menuDropDown {
        display: block;
        color: white;
        /* border: 1px solid red; */
        margin-top: -2px;
        font-size: 30px;
        padding-top: 2px;
        cursor: pointer;
        width: 50px;
        padding-left: 12px;
    }
    html[dir=rtl] .menuDropDown {
        padding-left: 0px;
        padding-right: 10px;
    }

    .menuDropDown:hover, .menuDropDown:active {
        background-color: #4974828b;
        transition: 0.4s linear;
    }
}

@media all and (max-width: 1000px) {
    .headerContainer {
        padding-top: 3.5%;
    }
}

@media all and (max-width: 850px) {
    .headerContainer {
        padding-top: 4%;
    }
}

@media all and (max-width: 800px) {
    .headerContainer {
        padding-top: 4.5%;
    }
}

@media all and (max-width: 700px) {
    .headerContainer {
        padding-top: 5%;
    }
}

/* For responsiveness */
@media all and (max-width: 600px) {
    .headerContainer {
        width:100% !important;
        padding-left: 0%;
        padding-right: 0%;
        margin-left: 0%;
        margin-right: 0%;
        /* border: 1px solid red; */
        flex-direction: column-reverse;
        height: auto;
        padding-top: 10px;
    }

    .mobileDropStyle {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 10px;
        /* border: 1px solid red; */
    }

    .logo {
        width: 240px;
        height: 60px;
        /* border: 1px solid red; */
        margin-top: -10px;
        margin-bottom: 10px;
    }

    .logoMobileContainer {
        /* border: 1px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
    }

    /* Inside Left & Right Containers */
    .leftContainer{
        /* border: 1px solid red; */
        display: block;
        margin-left: 0% !important;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    html[dir=rtl] .leftContainer {
        /* border: 1px solid rgb(0, 76, 255); */
        margin-right: 0% !important;
        margin-left: 0% !important;
        width: 100% !important;
    }


    .rightContainer {
        /* border: 1px solid rgb(149, 255, 0); */
        margin-right: 13.2%;
        display: block;
        width: 100%;
    }

    html[dir=rtl] .rightContainer {
        /* border: 1px solid rgb(0, 76, 255); */
        margin-right: 0% !important;
        margin-left: 0% !important;
        width: 100% !important;
    }

    .dropDownLang {
        background-color: #395a60 !important;
        border: 0.1px solid rgba(255, 255, 255, 0.179) !important;
    }
    
    .dropDownLang:hover {
        background-color: #395a60 !important;
        color: #395a60 !important;
    }
    

    /* Inside Left & Right Containers */
}

@media all and (max-width:350px) {
    .logo {
        width: 250px;
    }
}

@media all and (max-width:300px) {
    .logo {
        width: 230px;
    }
}