/******************************************** Login Container Styling ***************************************/

.loginContainer {
    background-color: #ffffff;
    width: 480px;
    padding-top: 50px;
    padding-bottom: 40px;
    border-radius: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.logoRight {
    width: 230px;
    padding-left: 6%;
}

html[dir=rtl] .logoRight {
    padding-right: 6%;
    padding-left: initial;
}

.titleLoginAccount {
    font-size: 33px;
    font-weight: 600;
    color: #487377;
    margin-top: 40px;
    padding-left: 6%;
}

html[dir=rtl] .titleLoginAccount {
    padding-right: 6%;
    padding-left: initial;
}

.form_inputs {
    margin-top: 20px;
    padding-left: 6%;
}

html[dir=rtl] .form_inputs {
    padding-right: 6%;
    padding-left: initial;
}

/* Label */
.label_info {
    font-size: 14px;
    font-weight: 400;
    color: #4b7579;
    margin-bottom: 10px;
    margin-top: 20px;
}

.email {
    width: 92%;
    border-radius: 5px;
    height: 50px;
    /* padding-left: 20px; */
    /* border: none; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.infoInputs {
    font-size: 14px;
    font-weight: 400;
    color: #ff0000;
    margin-bottom: 20px !important;
    margin-top: 2px;
}

html[dir=rtl] .email {
    /* padding-right: 20px; */
    padding-left: initial;
}

.inputValidatedFalse {
    border: 2px solid #ff0000;
}

.inputValidatedTrue {
    border: 2px solid #6bb6b5;
}

.password {
    width: 92%;
    border-radius: 5px;
    height: 50px;
    /* padding-left: 20px; */
    /* border: none; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

html[dir=rtl] .password {
    /* padding-right: 20px; */
    padding-left: initial;
}

.formLabelStyles {
    margin-top: 4px;
    padding-left: 9px;
    transition: 0.3s linear;
}

html[dir=rtl] .formLabelStyles {
    padding-right: 9px;
    padding-left: initial;
}

/* Validated Styles */
.validatedTrue {
    color: #6bb6b5;
}

/* Not validated Styles */
.validatedFalse {
    color: red;
}

.rememberMeBlock {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 400;
    color: #6bb6b5;
    display: flex;
    padding-left: 0px !important;
    /* border: 1px solid red; */
    /* direction: ltr; */
}

html[dir=rtl] .rememberMeBlock {
    width: 60%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.forgotPassword {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #4b7579;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.btn_login {
    margin-top: 15px;
    color: white;
    text-transform: none;
    border-radius: 5px;
    height: 50px;
    font-size: 20px;
    width: 92%;
    background-color: #d5b254;
}

.btn_login:hover {
    background-color: #d5b254;
    color: white;
}

.btn_login:focus {
    background-color: #d5b254 !important;
    color: white !important;
}

.btn_login:active {
    background-color: #d5b254 !important;
    color: white !important;
}

.rmt {
    padding-left: 4%;
    margin-top: -4px;
    display: block;
    font-size: 18px;
    color: #6bb6b5;
}

html[dir=rtl] .rmt {
    padding-right: 4%;
    padding-left: initial;
}

/* when button is pressed hold */
/******************************************** Login Container Styling ***************************************/

@media all and (max-width:1300px) {
    .loginContainer {
        width: 440px;
        /* padding-top: 50px;
        padding-bottom: 40px;
        border-radius: 10px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
    }
}

@media all and (max-width:1150px) {
    .loginContainer {
        /* margin-top: -12% !important;
        border: 10px solid red; */
        width: 480px;
        margin-bottom: 10px;
        margin-left: 0% !important;
        margin-right: 0% !important;
        /* padding-top: 50px;
        padding-bottom: 40px;
        border-radius: 10px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
    }
}

@media all and (max-width:991px) {
    .loginContainer {
        /* margin-top: -16% !important;
        border: 10px solid rgb(0, 8, 255); */
    }
}

@media all and (max-width:767px){
    .loginContainer {
        /* margin-top: -21% !important;
        border: 10px solid rgb(255, 0, 170); */
    }
}

@media all and (max-width:750px) {
    .loginContainer {
        border-radius: 40px;
        /* margin-top: -25% !important;
        border: 10px solid rgb(255, 0, 170); */
    }
}

@media all and (max-width:600px){
    .loginContainer {
        /* margin-top: -20% !important;
        border: 10px solid rgb(68, 255, 0); */
    }
}

@media all and (max-width:550px) {
    .loginContainer {
        width: 100%;
        /* border: 10px solid red !important; */
        /* margin-top: -20% !important; */
        /* height: 560px; */
        /* margin-top: 180px; */
        margin-top: 5% !important;
    }

    .logoRight {
        width: 180px;
        /* border: 1px solid red; */
        padding-left: initial;
    }

    .mobileCenter {
        display: flex;
        justify-content: center;
    }

    .titleLoginAccount {
        font-size: 28px;
        padding-left: 0%;
        text-align: center;
        /* border: 1px solid red; */
    }
}

@media all and (max-width:415px) {
    .titleLoginAccount {
        margin-left: 10%;
        width: 80%;
        margin-right: 10%;
    }

    html[dir=rtl] .titleLoginAccount {
        margin-left: 10%;
        width: 80%;
        margin-right: 10%;
    }

    .loginContainer {
        padding-top: 40px;
        /* border: 10px solid red !important; */
        /* margin-top: -24% !important; */
    }

    .rememberMeBlock {
        /* border: 1px solid red; */
        width: 100%;
        padding-right: 0%;
        display: flex;
    }

    html[dir=rtl] .rememberMeBlock {
        /* border: 1px solid red; */
        width: 100%;
        padding-right: 0%;
        display: flex;
    }

    .rmt {
        /* border: 1px solid rgb(134, 13, 13); */
        width: 100%;
        /* padding-right: 10%;
        padding-left: 2%; */
        padding-right: 2%;
    }


    html[dir=rtl] .rmt {
        /* border: 1px solid rgb(134, 13, 13); */
        width: 100%;
        /* padding-right: 10%;
        padding-left: 2%; */
        padding-left: 2%;
        padding-right: 0%;
    }
}

@media all and (max-width:390px) {
    .loginContainer {
        min-height: 530px;
        padding-top: 20px;
        border-radius: 30px;
        margin-top: 8% !important;
    }

    .titleLoginAccount {
        font-size: 25px;
        margin-top: 18px;
    }

    .form_inputs {
        /* border: 1px solid red; */
        padding-top: 0px;
        margin-bottom: 0px;
    }

    .label_info {
        margin-top: 10px;
        /* border: 1px solid red; */
    }

    .btn_login {
        font-size: 18px;
        height: 45px;
        padding-top: 5px;
    }

    .email, .password {
        height: 45px;
    }

    .logoRight {
        margin-top: 0px;
    }
}
@media all and (max-width:350px) {
    .loginContainer{
        margin-top: 12% !important;
    }
}
@media all and (max-width:325px) {
    .loginContainer {
        min-height: 510px;
        padding-top: 20px;
        margin-top: 15% !important;
    }

    .titleLoginAccount {
        font-size: 23px;
        margin-top: 15px;
    }

    .logoRight {
        width: 150px;
    }

    .btn_login {
        height: 40px;
        font-size: 18px;
        padding-top: 6px;
    }

    .email, .password {
        height: 40px;
    }
}