/* Main Container */
.containerCustom {
    /* border: 5px solid red; */
    /* display: flex;
    flex-direction: row; */
    min-height: 100vh;
    height: auto;
    padding-top: 10% !important;
    background: url(../../assets/Images/Home/login-background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/******************************************** Left Side ***************************************/
/* .leftSide { */
    /* border: 5px solid rgb(0, 255, 13); */
    /* width: 55%; */
    /* height: 100vh; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    /* padding-left: 13%; */
    /* padding-top: 14%; */
    /* border: 1px solid red; */
/* } */

/* html[dir=rtl] .leftSide { */
    /* padding-right: 7%; */
/* } */

.topContainerLeftLogin {
    display: flex;
    /* padding-right: 15%; */
    /* border: 2px solid red; */
}

.tabLogin {
    /* border: 2px solid rgb(115, 255, 0); */
    width: 220px;
    height: 45px;
    display: flex;
    border-radius: 25px;
    margin-top: 20px;
    /* background-color: #e59d43; */
}

.generalLeft {
    width: 120px;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    /* background-color: #e59d43 !important; */
    /* padding-top: 10px; */
    position: relative;
    z-index: 1;
}

.leftTabLogin {
    background-color: #d5b254 !important;
    color: #ffffff;
    padding-left: -12px;
    cursor: pointer;
}

.leftSelectedTabLogin {
    background-color: #ffffff;
    color: #436368;
    border-radius: 25px !important;
    padding-left: -10px;
}

.generalRight {
    text-align: center;
    /* padding-top: 10px; */
    font-size: 15px;
    margin-left: -15px;
    font-weight: 500;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    position: relative;
    color: #436368;
    /* background-color: #ffffff !important; */
    cursor: pointer;
    overflow: hidden;
}

.rightTabLogin {
    background-color: #d5b254;
    width: 140px;
    padding-left: 12px;
}

.rightSelectedTabLogin {
    background-color: white !important;
    border-radius: 25px !important;
    width: 160px;
    z-index: 1 !important;
}

.announcementListContainer {
    /* border: 3px solid red; */
    width: 625px;
    height: 500px;
    overflow-y: auto;
    /* margin-right: 50px; */
}

/* html[dir='rtl'] .announcementListContainer { */
    /* margin-right: 15%; */
/* } */

/* Works on Chrome, Edge, and Safari */
.announcementListContainer::-webkit-scrollbar {
    width: 5px;
}

.announcementListContainer::-webkit-scrollbar-track {
    background: #bcc9cb;
    border-radius: 5px;
}

.announcementListContainer::-webkit-scrollbar-thumb {
    background-color: #d5b254;
    border-radius: 20px;
    /* border: 3px solid orange; */
}

.announcementSubContainer {
    width: 95%;
    height: 70px;
    background: transparent;
    border-radius: 40px;
    display: flex;
    margin-bottom: 10px;
    border: 1px solid white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

html[dir=rtl] .announcementSubContainer {
    padding-right: 5%;
}

.descDAnnouncementSC {
    /* border: 1px solid rgb(0, 140, 255); */
    margin-top: -15px;
    font-size: 12px;
    line-height: 15px;
    padding-right: 30%;
}

html[dir=rtl] .descDAnnouncementSC {
    padding-left: 20% !important;
    padding-right: 0%;

}

.leftAnnouncementSubContainer {
    /* border: 1px solid red; */
    width: 25%;
    padding-left: 5%;
    color: #ffffff;
    padding-top: 5px;
}

html[dir=rtl] .leftAnnouncementSubContainer {
    /* border: 1px solid red; */
    padding-right: 2%;
    width: 20%;
}

.dateCountAnnouncementSC {
    font-size: 25px;
    font-weight: bold;
}

.dateMonthAnnouncementSC {
    font-size: 15px;
    font-weight: 600;
    margin-top: -50px !important;
}

.rightAnnouncementSubContainer {
    /* border: 1px solid green; */
    width: 75%;
    color: #ffffff;
}
/* 
html[dir=rtl] .rightAnnouncementSubContainer {
    border: 1px solid red;
} */

.descHAnnouncementSC {
    /* border: 1px solid red; */
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
}

.left_heading_about {
    font-size: 33px;
    font-weight: 600;
    color: #d5b254;
}

.desc_left_login {
    font-size: 18px;
    font-weight: 400;
    color: #fdfdfd;
    margin-top: 0px;
    width: 400px;
}

.heading_info_login {
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 20px;
    line-height: 43px;
}

.bottom_desc_login {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    color: #aab9bb;
    /* border: 1px solid red; */
    margin-top: 15px;
}
/* 
.bottomContainerTopContainer {
    padding-right: 15%;
} */

.btn_learn_more {
    margin-top: 10px;
    text-transform: none;
    color: #d5b254;
    font-size: 16px;
    border-width: 1px;
    height: 45px;
    border-radius: 7px;
    width: 140px !important;
    background-color: transparent;
}

.findustext {
    margin-top: 30px;
    font-size: 20px;
    color: #aab9bb;
}

.socialIconsList {
    display: flex;
    flex-direction: row;
    list-style: none;
    /* border: 1px solid red; */
    margin-left: -30px;
    margin-right: -10%;
    margin-top: 20px;
}

.socialIconsList li {
    margin-right: 20px;
}


/******************************************** Left Side ***************************************/

/******************************************** Right Side ***************************************/
.rightSide {
    /* width: 45%; */
    /* padding-top: 200px; */
    /* padding-left: 6%; */
    /*
    padding-right: 40%; */
    /* height: 100vh; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    display: flex;
    flex-direction: row;
    align-items: right;
    align-items: flex-end;
    justify-content: flex-end;
    /* border: 2px solid white; */
}

html[dir=rtl] .rightSide {
    padding-right: 6%;
}

/* when button is pressed hold */
/******************************************** Right Side ***************************************/

@media all and (max-width:1550px) {
    .containerCustom {
        padding-top: 20vh;
    }

    /* .leftSide { */
        /* border: 2px solid rgb(128, 0, 0); */
        /* padding-left: 5%; */
        /* padding-top: 15%; */
    /* } */

    /* .rightSide { */
        /* border: 2px solid rgb(255, 255, 255); */
    /* } */
}

@media all and (max-width:1399px) {
    .mobileTabLogin {
        margin-left: -100px;
    }

    .desc_left_login {
        font-size: 18px;
        padding-right: 100px;
    }

    /* Do corrections for rtl */
    html[dir=rtl] .desc_left_login {
        padding-left: 100px;
        padding-right: 0px;
    }

    html[dir=rtl] .mobileTabLogin {
        margin-right: -100px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 1330px) {
    /* .leftSide { */
        /* border: 2px solid rgb(128, 0, 0); */
        /* padding-left: 2%;
        padding-top: 15%; */
        /* padding-top: 18%; */
    /* } */

    /* .rightSide { */
        /* border: 3px solid rgb(255, 255, 255); */
        /* padding-left: 2%; */
    /* } */
}

@media screen and (max-width: 1250px) {
    .leftSide {
        padding-left: 3%;
    }

    .rightSide {
        /* border: 3px solid rgb(255, 255, 255); */
        padding-left: 2%;
    }

    /* For rtl */
    html[dir=rtl] .leftSide {
        padding-left: 0%;
        padding-right: 3%;
    }

    html[dir=rtl] .rightSide {
        padding-left: 0%;
        padding-right: 2%;
    }
}



@media screen and (max-width:1200px) {
    .left_heading_about {
        font-size: 31px;
    }

    .desc_left_login {
        font-size: 18px;
        padding-right: 200px;
        /* border: 2px solid red; */
    }

    .topContainerLeftLogin {
        /* border: 1px solid green; */
        padding-right: 20%;
        flex: 2;
    }

    html[dir=rtl] .desc_left_login {
        padding-left: 200px;
        padding-right: 0px;
    }

    html[dir=rtl] .topContainerLeftLogin {
        padding-left: 20%;
        padding-right: 0px;
    }
}

@media all and (max-width:1199px) {
    .mobileTabLogin {
        margin-left: -170px;
    }

    html[dir=rtl] .mobileTabLogin {
        margin-right: -170px;
        margin-left: 0px;
    }
    .announcementListContainer {
        width: 95%;
        height: 530px;
    }
}

/* Changing the overall look here */
@media screen and (max-width:1150px) {
    .hideForMobile {
        display: none !important;
    }

    .mobileTabLogin {
        display: none;
    }

    .announcementListContainer {
        width: 100%;
        height: 530px;
    }

    .leftSide {
        width: 80%;
        /* padding-top: 8%; */
        padding-bottom: 30px;
        margin-top: 30px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        /* border: 2px solid rgb(21, 0, 255); */
    }

    .containerCustom{
        /* border: 1px solid red; */
        display: flex;
    }
    .aboutContainer{
        width: 100%;
        /* border: 2px solid red; */
    }

    .rightSide {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
        /* border: 2px solid red; */
    }

    html[dir=rtl] .leftSide {
        padding-left: 0%;
        padding-right: 13%;
    }

    html[dir=rtl] .rightSide {
        padding-left: 0%;
        padding-right: 0%;
    }

    .topContainerLeftLogin {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    /* Main Container */
    .containerCustom {
        /* border: 10px solid rgb(255, 0, 21); */
        margin-left: 0% !important;
        margin-right: 0% !important;
        padding-left: 0% !important;
        padding-right: 0% !important;
        flex-direction: column-reverse;
        height: auto;
    }

    .desc_left_login {
        padding-right:initial;
        /* border: 2px solid rgb(0, 157, 255); */
    }
    html[dir=rtl] .desc_left_login {
        padding-left:initial;
        padding-right:initial;
    }

    .heading_info_login {
        margin-top: 0px;
            }
}

@media all and (max-width:900px) {
    .announcementListContainer {
        width: 100%;
    }
}

@media all and (max-height: 800px) {
    .containerCustom {
        padding-top: 8vh;
    }
}

@media screen and (max-width:850px) {
    .leftSide {
        padding-left: 10%;
    }

    html[dir=rtl] .leftSide {
        padding-left: 0%;
        padding-right: 10%;
    }
}

@media all and (max-width:800px) {
    .leftSide {
        width: 200% !important;
        margin-top: 50px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        /* border: 2px solid rgb(174, 255, 0); */
    }
}

@media screen and (max-width:767px) {
    .dateCountAnnouncementSC {
        font-size: 22px;
    }
    .dateMonthAnnouncementSC {
        font-size: 15px;
        padding-top: 2px;
    }
}

@media screen and (max-width:750px) {
    .announcementListContainer,.aboutContainer {
        width: 100%;
        /* border: 2px solid red; */
    }

    .leftSide {
        width: 95% !important;
        margin-top: 40px;
        padding-left: 0px !important;
        padding-right:0px !important;
        margin-left: 2.5% !important;
        margin-right: 2.5% !important;
        /* border: 2px solid rgb(174, 255, 0); */
    }

    html[dir=rtl] .leftSide {
        width: 95% !important;
        margin-top: 40px;
        padding-left: 0px !important;
        padding-right:0px !important;
        margin-left: 2.5% !important;
        margin-right: 2.5% !important;
        /* border: 2px solid rgb(174, 255, 0); */
    }

    .rightSide {
        /* margin-top: 40px; */
        width: 100%;
        padding-top: 20px;
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
    }

    html[dir=rtl] .rightSide {
        padding-top: 20px;
    }

    .topContainerLeftLogin {
        flex-direction: column-reverse;
        padding-right: none;
        padding-right: 0% !important;
    }

    html[dir=rtl] .topContainerLeftLogin {
        padding-left: 0% !important;
    }

    .tabLogin {
        width: 100%;
        /* border: 1px solid red; */
        width: fit-content;
    }

    .mobileTabLogin {
        display: flex;
        justify-content: center;
        /* border: 10px solid yellow; */
        width: 100% !important;
        margin-bottom: 20px;
    }

    .heading_info_login {
        font-size: 24px;
        line-height: 35px;
        font-weight: 500;
    }
}

@media all and (max-width:600px){
    .topContainerLeftLogin{
        margin-top: 40px;
    }
    .rightSide{
        /* margin-top: 50px; */
        padding-left: 0px !important;
        padding-right:0px !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
        /* border: 2px solid rgb(255, 0, 0) !important; */
    }
    .leftSide {
        width: 84% !important;
        margin-top: 90px !important;
        padding-left: 0px !important;
        padding-right:0px !important;
        margin-left: 8% !important;
        margin-right: 8% !important;
        /* border: 2px solid rgb(34, 0, 255); */
    }
    html[dir=rtl] .leftSide{
        width: 84% !important;
        margin-top: 90px !important;
        padding-left: 0px !important;
        padding-right:0px !important;
        margin-left: 8% !important;
        margin-right: 8% !important;
        /* border: 2px solid rgb(34, 0, 255); */
    }

    .containerCustom{
        margin-right: 0px !important;
        margin-left: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        width: 100% !important;
        /* border: 2px solid rgb(81, 255, 0); */
    }

    .heading_info_login {
margin-top: 0px;
    }

    .bottom_desc_login {
        /* border: 1px solid red; */
        margin-top: 5px;
    }
}

@media all and (max-width:550px) {
    .rightSide {
        width: 95% !important;
        margin-left: 2.5% !important;
        margin-right: 2.5% !important;
        /* border: 2px solid red; */
    }

    html[dir=rtl] .rightSide {
        width: 95% !important;
        margin-left: 2.5% !important;
        margin-right: 2.5% !important;
    }

    .mobileCenter {
        display: flex;
        justify-content: center;
    }

}

@media all and (max-width:480px) {

    .topContainerLeftLogin{
        margin-top: 50px !important;
    }

    .announcementSubContainer {
        height: 100px;
        padding-top: 7px;
    }

    .leftAnnouncementSubContainer {
        padding-left: 10%;
    }

    html[dir=rtl] .leftAnnouncementSubContainer {
        padding-left: 0%;
        padding-right: 5%;
    }

    .dateCountAnnouncementSC {
        font-size: 20px;
    }

    .dateMonthAnnouncementSC {
        /* border: 1px solid red; */
        line-height: 20px;
        padding-top: 10px;
    }
    
    html[dir=rtl] .announcementSubContainer {
        padding-right: 0%;
    }
    
    .descDAnnouncementSC {
        /* border: 1px solid rgb(0, 140, 255); */
        padding-right: 2%;
    }
    
    html[dir=rtl] .descDAnnouncementSC {
        padding-left: 2% !important;
        padding-right: 0%;
    
    }
}

@media all and (max-width:440px) {
    .announcementSubContainer {
        height: 120px;
        padding-top: 7px;
    }
}

@media all and (max-width:430px) {
    .desc_left_login {
        /* border: 1px solid red; */
        width: 100%;
    }
}

@media all and (max-width:390px) {
    .announcementSubContainer {
        height: 115px;
        padding-top: 10px;
    }

    .rightAnnouncementSubContainer {
        padding-right: 0%;
        padding-left: 2%;
        /* border: 1px solid red; */
    }

    html[dir=rtl] .rightAnnouncementSubContainer {
        padding-left: 0%;
        padding-right: 2%;
    }
}

@media all and (max-width:350px) {
    .announcementSubContainer {
        height: 130px;
        padding-top: 12px;
    }
}