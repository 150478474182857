/* Main Container */
.containerCustom {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: 100%;
    background: url(../../assets/Images/Home/login-background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
}

/******************************************** Forget Password Container ***************************************/
.forgetPasswordContainer {
    width: fit-content;
    /* border: 1px solid red; */
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff00;
}

.loginContainer {
    background-color: #ffffff;
    width: 480px;
    padding-top: 50px;
    padding-bottom: 40px;
    border-radius: 10px;
    margin-top: 150px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
html[dir=rtl] .loginContainer{
    /* padding-left: none; */
    /* border: 1px solid red; */
}

.logo {
    width: 270px;
    padding-left: 40px;
}
html[dir=rtl] .logo{
    padding-right: 40px;
}



.logoLock{
    /* border: 1px solid red; */
    width: fit-content;
    display: block;
    margin-top: 30px;
    width: 150px;
    /* height: 300px */
    margin-left: auto;
    margin-right: auto;
}

.titleForgetPassword {
    font-size: 30px;
    font-weight: 600;
    color: #33565b;
    margin-top: 40px;
    text-align: center;
}
.infoForgotPassword{
    text-align: center;
    color: #707070;
}

.form_inputs {
    margin-top: 20px;
}

html[dir=rtl] .emails {
    margin-right: 8%;
}

/* Label */
.label_info {
    font-size: 14px;
    font-weight: 400;
    color: #535353;
    margin-bottom: 10px;
    margin-top: 10px;
    /* border: 1px solid red; */
    margin-left: 7.5%;
}
html[dir=rtl] .label_info{
    margin-right: 7.5%;
    margin-left: initial;
}

.email {
    width: 85%;
    font-size: 15px;
    border-radius: 8px;
    height: 50px;
    margin-left: 7.5%;
    padding-left: 20px;
    border: none;
    color: #a4a4a4;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
html[dir=rtl] .email{
    margin-right: 7.5%;
    margin-left: initial;
}

.btn_forgetPassword {
    margin-top: 20px;
    margin-bottom: 15px;
    color: white;
    text-transform: none;
    border-radius: 5px;
    height: 50px;
    font-size: 15px;
    width: 85%;
    margin-left: 7.5%;
    background-color: #d5b254;
}
html[dir=rtl] .btn_forgetPassword{
    margin-right: 7.5%;
    margin-left: initial;
}

.btn_forgetPassword:hover {
    background-color: #e98b18;
    color: white;
}

.btn_forgetPassword:active {
    background-color: #e98b18 !important;
    color: white !important;
}

/* when button is pressed hold */
/******************************************** Right Side ***************************************/

/* For responsiveness */
@media all and (max-width: 768px) {
    .forgetPasswordContainer{
        margin-top: 40px;
        width: 95vw;
        /* border: 2px solid red; */
        display: flex;
        justify-content: center;
    }
    .loginContainer{
        /* border: 2px solid green; */
        height: 620px;
        border-radius: 40px;
    }
}
@media all and (max-width:480px){
    .loginContainer{
        width:100%;
        height: 560px;
        margin-top: 180px;
    }
    .btn_forgetPassword{
        margin-top: 30px;
    }
    .logoLock{
        /* border: 1px solid red; */
        width: 120px;
    }
    .logo{
        width: 180px;
        /* border: 1px solid red; */
        padding-left: initial;
    }
    .logo_mobile_container{
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
    }
    .titleForgetPassword{
        font-size: 25px;
    }
    .infoForgotPassword{
        font-size: 14px;
    }
}

