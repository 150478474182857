.container {
    /* border: 1px solid red; */
    width: 100% !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
}



  