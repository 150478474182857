.container {
    /* border: 1px solid red; */
    width: 100% !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}


.togglebutton {
    width: 50px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
  }
  
  .slider {
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s;
  }
  
  .togglebutton.checked .slider {
    transform: translateX(20px);
  }
  
