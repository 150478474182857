.container {
    border-radius: 3px;
    /* border: 10px solid rgb(255, 0, 0) !important; */
    width: 98.4%;
    height: 100%;

    /* padding Covered Here */
    margin-left: 0.8%;
    margin-right: 0.8%;
    margin-top: 1.5%;

    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.insideTableContainer {
    /* border: 1px solid rgb(0, 0, 255) !important; */
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

@media all and (max-width:600px){
    .insideTableContainer {
        height: 400px !important;
    }
}

.universityLogo {
    width: 100px;
    height: 100px;
    /* width: 30%;
    height: 177px; */
    /* display: block; */
    /* margin-right: 5px; */
    /* padding-left: 10px; */
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;

}

.infoICB {
    font-size: 14px;
    color: black;
    font-weight: bold;
    margin-top: -10px;
}

.textStyle{
    font-size: 14px;
    color: black;
    font-weight:normal;
    margin-top: -10px;
}