.container {
    /* border: 1px solid red; */
    width: 100% !important;
    /* padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px; */
    padding: 5px !important;
}

.SnackBar {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1000; /* Ensure it's above other content */
}
